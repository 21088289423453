<template>
    <input type="hidden" name="talon6" id="talon6" ref="talonInput" value="{'version': 6, 'status': -1}">
</template>

<script>
export default {
    created() {
        let ehawkTalonScript = document.createElement('script');
        ehawkTalonScript.setAttribute('src', 'https://files.keap.com/js/EHawkTalon-v6.js?v=6.0.7');
        document.head.appendChild(ehawkTalonScript);
        window.eHawkTalonSettings = {
            cookie: {
                SameSite: 'Lax',
                Secure: true,
            },
            bind: {
                OutId: 'talon6',
            },
            autoLoad: true,
        };
    },
    expose: [
        'updateTalon'
    ],
    methods: {
        async updateTalon() {
            await this.$store.commit('updateTalon', this.$refs.talonInput.value);
        }
    }
}
</script>
